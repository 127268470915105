.firstGindom,
.firstCube {
	@extend .firstSlider;
	position: relative;
	z-index: 100;
	@include screen ('tabletland') {
		margin-top: 0 !important;
		position: absolute;
		top: unset;
    	transform: unset;
	}
	figure {
		@include screen ('tabletport') {
			min-height: 600px;
		}
		@include screen ('tabletland') {
			min-height: 450px;
		}
		@include screen ('large') {
			min-height: 600px;
		}
		img {
			width: 75vw;
			left: 10vw;
			@include screen ('tabletport') {
				width: 550px;
				left: 120px;
			}
			@include screen ('tabletland') {
				width: 450px;
    			left: unset;
    			right: 0;
			}
			@include screen ('large') {
				width: 100%;
			}
		}
		.square {
			left: 30vw;
			top: unset;
			bottom: 19vw;
			@include screen ('tabletport') {
				width: 300px;
				height: 300px;
				bottom: unset;
				top: 210px;
				left: 240px;
			}
			@include screen ('tabletland') {
				top: 120px;
				left: 120px;
			}
			@include screen ('large') {
				left: 135px;
				width: 330px;
				height: 330px;
				top: 210px;
			}
		}
		figcaption {
			text-align: left;
			top: unset;
			bottom: 41vw;
			left: 2vw;
			font-size: 4vw;
			@include screen ('tabletport') {
				font-size: $font-size-base;
				bottom: unset;
				top: 348px;
				left: 70px;
				width: 300px;
			}
			@include screen ('tabletland') {
				top: 258px;
    			left: -50px;
			}
			@include screen ('large') {
				left: -40px;
				top: 378px;
			}
		}
	}
}

.firstCube {
	figure {
		img {
			left: 5vw;
			@include screen ('tabletport') {
				left: 40px;
			}
			@include screen ('tabletland') {
				left: -40px;
			}
			@include screen ('large') {
				left: -80px;
			}
		}
		figcaption {
			left: unset;
			right: -8vw;
			@include screen ('tabletport') {
				right: 10px;
			}
			@include screen ('tabletland') {
				right: -110px;
			}
			@include screen ('large') {
				right: -70px;
			}
		}
	}
}

.intro {
	position: relative;
	z-index: 100;
	@include screen ('tabletland') {
		margin-top: 80px;
		min-height: 540px;
	}
	@include screen ('large') {
		min-height: 600px;
	}
	.container {
		position: relative;
		@include screen ('tabletland') {
			padding-top: 120px;
		}
		@include screen ('large') {
			padding-top: 210px;
		}
		.decoText {
			font-size: 49px;
			line-height: 44px;
			top: -40px;
			@include screen ('tabletport') {
				font-size: 90px;
				line-height: 80px;
				top: -55px;
				left: -8%;
			}
			@include screen ('tabletland') {
				left: -20%;
			}
		}
		img {
			width: 100%;
			max-width: 320px;
			margin-bottom: 15px;
		}
		.btn {
			margin-top: 10px;
			margin-right: 8px;
		}
	}
}

.productMenu {
	position: relative;
	text-align: center;
	width: 100%;
	height: 48px;
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
	transition: box-shadow 800ms;
	background-color: #fff;
	@include screen ('tabletport') {
		height: 58px;
		overflow: hidden;
	}
	.prodMenuMain,
	.prodMenuSec {
		display: inline-block;
		font-weight: 700;
		padding: 15px 0;
		text-transform: uppercase;
		font-size: 15px;
		@include screen ('tabletland') {
			padding: 20px 0;
		}
		a {
			padding: 0 20px;
		}
	}
	.prodMenuSec {
		background-color: $white;
		font-weight: 400;
	}
}

#fasi {
	padding-top: 60px;
	margin-bottom: 45px;
	@include screen ('tabletland') {
		padding-top: 120px;
		margin-bottom: 90px;
	}
	.slider {
		.slick-dots {
			@include screen ('tabletport') {
				padding-left: 40px;
			}
		}
	}
	.fasiSlider {
		.fase {
			p {
				margin-left: -40px;
				@include screen ('tabletport') {
					margin-left: 0;
				}
			}
		}
	}
	.optSlider {
		margin-top: 60px;
		margin-bottom: 60px;
		@include screen ('tabletland') {
			margin-top: 0px;
			margin-bottom: 90px;
		}
		.fase {
			padding-left: 0;
			@include screen ('tabletport') {
				padding-left: 40px;
			}
		}
		.fase:first-child {
			background-color: $gray-lighter;
			margin-top: 40px;
			text-align: center;
			padding-top: 75px;
			@include screen ('tabletport') {
				padding-top: 60px;
			}
			@include screen ('tabletland') {
				padding-top: 100px;
				margin-top: 30px;
			}
			@include screen ('large') {
				padding-top: 120px;
			}
			p {
				@include screen ('tabletland') {
					padding-right: 40px;
				}
			}
			&:hover p {
				opacity: 1;
			}
		}
	}
	.fase {
		padding-top: 40px;
		padding-left: 40px;
		position: relative;
		min-height: 180px;
		@include screen ('mini') {
			min-height: 200px;
		}
		@include screen ('tabletport') {
			min-height: 150px;
		}
		@include screen ('tabletland') {
			display: inline-block;
			width: 33%;
			vertical-align: top;
			min-height: 230px;
			&:hover {
				.decoText {
					opacity: 1;
				}
				.fasiImg {
					opacity: 1;
				}
				p {
					opacity: 0;
				}
			}
		}
		@include screen ('large') {
			min-height: 280px;
		}
		.decoText {
			opacity: 1;
			left: -5px;
			top: 60px;
			font-size: 180px;
			transition: opacity 400ms;
			@include screen ('tabletland') {
				opacity: .11;
			}
		}
		.fasiImg {
			margin-bottom: 20px;
			@include screen ('tabletland') {
				opacity: 0;
				transition: opacity 600ms;
			    position: absolute;
			    top: 30px;
		        left: 40px;
	    		width: calc(100% - 45px);
			    height: auto;
			    z-index: -1;
			}
		}
		p {
			opacity: 1;
			transition: opacity 400ms;
		}
	}
	.optionalImage {
		img {
			width: 100%;
		}
	}
	.optionalText {
		h4 {
			@include screen ('tabletport') {
				margin-bottom: 40px;
			}
		}
	}
	.panel {
		background-color: $white;
		padding: 15px 20px;
		margin-bottom: 15px;
		a {
			display: block;
			margin-bottom: 0;
			position: relative;
			font-size: 15px;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				width: 20px;
				height: 20px;
				background-image: url('../../../assets/images/elements/arrow.svg');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				transform: rotate(-180deg);
				transition: transform 400ms;
			}
		}
		a.collapsed {
			&:after {
				transform: rotate(0deg);
			}
		}
		p {
			margin-bottom: 0;
		}
		.panel-collapse {
			margin-top: 10px;
			font-size: 15px;
		}
	}
}

@keyframes blink { 
   0% {
	    -moz-transform: scale(0);
	    opacity: 0.0;
	 }
	 25% {
	    -moz-transform: scale(0);
	    opacity: 0.2;
	 }
	 50% {
	    -moz-transform: scale(0.1);
	    opacity: 0.4;
	 }
	 75% {
	    -moz-transform: scale(0.5);
	    opacity: 0.8;
	 }
	 100% {
	    -moz-transform: scale(1);
	    opacity: 0.0;
	 }
}

#function {
	min-height: 400px;
	color: #fff;
	&:after {
		content: '';
		background-color: #fff;
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
	    height: 100%;
	    width: 12px;
	    top: 0;
	    right: 14px;
	    z-index: 1000;
	    position: absolute;
	    animation: blink 1200ms step-end infinite alternate;
	    @include screen ('tabletport') {
			right: 19px;
	    }
	    @include screen ('large') {
	    	content: none;
	    }
	}
	h2 {
		margin-top: 30px;
	}
	.functionContainer {
		width: calc(100% - 30px);
		overflow-x: scroll;
		overflow-y: visible;
		white-space: nowrap;
		margin: 0 15px;
		padding: 45px 15px;
		text-align: center;
		height: 575px;
		@include screen ('tabletport') {
			width: calc(100% - 40px);
			margin: 0 20px;
		}
		@include screen ('large') {
			overflow-x: hidden;
			overflow-y: visible;
		}
		.functionContent {
			position: relative;
			overflow: visible;
			margin: auto;
			width: 1170px;
			height: 240px;
			img {
				max-width: none;
				width: 1170px;
				height: 240px;
			}
		}
	}
}

.actionPoint,
.actionPoint > .pulse,
.actionPoint > .dot {
	&:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	outline: none;
    	box-shadow: none;
    }
}

.actionPoint {
	position: absolute;
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
    -webkit-appearance: none;
    background: transparent;
    border: 0px solid transparent;
    padding: 0;
    line-height: 0px;
    font-size: 0px;
    vertical-align: top;
    border-radius: 50% 50%;

	.pulse {
	  width: 24px;
	  height: 24px;
	  border: 6px solid #fff;
	  border-radius: 30px;
	  background-color: $brand-primary;
	  z-index: 10;
	  position: absolute;
	  top: 0;
	  left: 0;
	}

	.dot {
	  border: 10px solid #fff;
	  background: transparent;
	  border-radius: 60px;
	  height: 60px;
	  width: 60px;
	  animation: pulse 1.5s ease-out;
	  animation-iteration-count: infinite;
	  position: absolute;
	  top: -18px;
	  left: -18px;
	  z-index: 1;
	  opacity: 0;
	}

	@-moz-keyframes pulse {
	 0% {
	    -moz-transform: scale(0);
	    opacity: 0.0;
	 }
	 25% {
	    -moz-transform: scale(0);
	    opacity: 0.1;
	 }
	 50% {
	    -moz-transform: scale(0.1);
	    opacity: 0.3;
	 }
	 75% {
	    -moz-transform: scale(0.5);
	    opacity: 0.5;
	 }
	 100% {
	    -moz-transform: scale(1);
	    opacity: 0.0;
	 }
	}

	@-webkit-keyframes "pulse" {
	 0% {
	    -webkit-transform: scale(0);
	    opacity: 0.0;
	 }
	 25% {
	    -webkit-transform: scale(0);
	    opacity: 0.1;
	 }
	 50% {
	    -webkit-transform: scale(0.1);
	    opacity: 0.3;
	 }
	 75% {
	    -webkit-transform: scale(0.5);
	    opacity: 0.5;
	 }
	 100% {
	    -webkit-transform: scale(1);
	    opacity: 0.0;
	 }
	}
}

.act1 {
	top: 36%;
    left: 7%;
}

.act2 {
	top: 32%;
    left: 21%;
}

.act3 {
	top: 48%;
    left: 46%;
}

.act4 {
	top: 38%;
    left: 63%;
}

.act5 {
	top: 36%;
    left: 83%;
}

/* .act1 {
    top: 85px + 115px;
    left: 100px;
}

.act2 {
    top: 85px + 115px;
    left: 100px;
}

.act3 {
    top: 85px + 115px;
    left: 100px;
}

.act4 {
    top: 85px + 115px;
    left: 100px;
}

.act5 {
    top: 85px + 115px;
    left: 100px;
} */

.popover {
	border-radius: 0px;
	border: 0px;
	padding: 0;
	box-shadow: 0 20px 30px 0 rgba(44, 44, 44, 0.3);
	color: $brand-primary;
	font-size: 15px;
	z-index: 500;
	p {
		margin: 0 25px 25px;
		font-size: 15px;
	}
	p.lead {
		margin-top: 25px;
		margin-bottom: 0;
	}
	.popover-title {
		background-color: #fff;
		border-bottom: 0px;
		text-transform: uppercase;
		font-weight: 700;
		
		padding: 0;
		margin-bottom: 5px;
	}
	.popover-content {
		padding: 0;
		img {
			width: 100% !important;
			height: auto !important;
		}
	}
}

#clean {
	padding-top: 15px;
	margin-bottom: 90px;
	@include screen ('tabletland') {
		margin-bottom: 160px;
	}
	h3 {
		margin-bottom: 25px;
	}
	.cleanSlider {
		padding: 0;
		margin: 0 -15px 60px;
		@include screen ('tabletport') {
			margin-bottom: 90px;
		}
		.cleanSlide {
			padding: 0 20px;
			@include screen ('tabletland') {
				display: inline-block;
				width: 25%;
				vertical-align: top;
			}
			figure {
				margin-bottom: 25px;
				img {
					width: 100%;
				}
			}
		}
		.slick-dots {
			left: 15px;
		}
	}
}

#det {
	.decoText {
		font-size: 49px;
		line-height: 44px;
		left: -8%;
		top: -30px;
		@include screen ('tabletland') {
			font-size: 90px;
			line-height: 80px;
			left: -20%;
			top: -60px;
		}
	}
	.detSlider {
		margin-top: 20px;
		figure {
			position: relative;
			text-align: center;
			@include screen ('tabletport') {
				width: 33%;
			    display: inline-block;
			    vertical-align: top;
			    padding: 0 20px;
			}
			.square {
				width: 170px;
				height: 170px;
				background-color: $brand-primary;
				box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.3);
				position: absolute;
				z-index: -1;
				left: 0;
				right: 0;
				margin: auto;
			}
			img {
				display: block;
				margin: auto;
				width: 115px;
				margin-top: 20px;
				margin-bottom: 10px;
			}
			figcaption {
				text-align: left;
				max-width: 220px;
    			margin: auto;
				ul {
					padding-left: 20px;
					list-style-type: none;
					li {
						position: relative;
						&:before {
							content: '';
							width: 2px;
							height: 2px;
							background-color: $brand-primary;
							position: absolute;
							top: 10px;
							left: -12px;
						}
					}
				}
			}
		}
		.slick-dots {
			text-align: center;
		}
	}
}

#app {
	margin-bottom: 120px;
	@include screen ('tabletland') {
		margin-bottom: 180px;
	}
	h2 {
		margin-bottom: 45px;
	}
	.appSlider {
		margin: 0 -20px;
		.appSlide {
			padding: 0 20px;
		}
		.slick-dots {
			padding: 0 20px;}
	}
}

#disc {
	padding: 45px 0;
	img {
		margin: -90px auto 30px;
		display: block;
		@include screen ('tabletport') {
			float: right;
			width: 58.33333%;
		}
		@include screen ('tabletland') {
			width: 66.66667%;
			margin-top: -120px;
		}
		@include screen ('large') {
			margin-right: 8.33333%;
			width: 45%;
		}
	}
}

#vantaggi {
	margin-bottom: 100px;
	.vantaggiSlider {
		margin-bottom: 60px;
		figure {
			margin: 0 20px 40px;
			img {
				width: 100%;}
		}
		.slick-dots {
			margin: 0 20px;
			bottom: 0;
		}
		.slick-dots li button:before {
			color: $brand-success;
		}
	}
	.vantaggiList {
		list-style-type: none;
		li {
			position: relative;
			&:before {
				content: '';
				width: 10px;
				height: 10px;
				background-color: $brand-success;
				position: absolute;
				top: 6px;
				left: -25px;
			}
		}
	}
}

#cube {
	ul {
		border-bottom: 0px;
		margin: 0 15px;
		@include screen ('tabletport') {
			margin: 0 20px;
		}
		li {
			display: inline-block;
			width: 50%;
			background-color: #fff;
			transition: background-color 400ms;
			&:hover {
				background-color: darken($white, 7%);
			}
		    &:focus,
		    &:hover:focus,
		    &.focus,
		    &:active,
		    &.active,
		    &:active:focus,
		    &:active:hover {
		    	background-color: $white;
		    }
		    &:first-child {
		    	@include screen ('tabletport') {
		    		text-align: right;
		    	}
		    }
		    &:last-child {

		    }
			#navBasic,
			#navPlus {
				display: inline-block;
				border: 0;
				border-radius: 0;
				padding: 30px;
				background-color: transparent;
				text-align: center;
				width: 100%;
				margin: 0;
				@include screen ('tabletport') {
					text-align: left;
					width: (650px / 2);
					padding-top: 50px;
					padding-bottom: 30px;
					padding-right: 30px;
					padding-left: 0px;
				}
				@media (min-width: 815px) {
					width: (720px / 2);
				}
				@include screen ('tabletland') {
					width: (912px / 2);
				}
				@media (min-width: 1064px) {
					width: (962px / 2);
				}
				@include screen ('large') {
					width: (1090px / 2);
				}
				@media (min-width: 1280px) {
					width: (1140px / 2);
				}
				&:hover,
			    &:focus,
			    &:hover:focus,
			    &.focus,
			    &:active,
			    &.active,
			    &:active:focus,
			    &:active:hover {
			    	color: darken($brand-success, 15%);
			    }
			}
			#navPlus {
	    		@include screen ('tabletport') {
	    			padding-left: 30px;
	    			padding-right: 0px;
	    		}
	    		@include screen ('large') {
	    			padding-left: 120px;
	    			padding-right: 15px;
	    		}
			}
		}
	}
	.decoText {
		color: $brand-success;
		font-size: 49px;
		line-height: 44px;
		@include screen ('tabletport') {
			font-size: 90px;
			line-height: 80px;
		}
	}
	.basicImg,
	.plusImg {
		display: block;
		margin: auto;
		max-width: 100%;
		@include screen ('large') {
			margin-left: 50px;
			max-width: 100%;
		}
	}
	.basicTxt,
	.plusTxt {
		text-align: center;
		@include screen ('tabletport') {
			text-align: left;
		}
	}
	.tabSection {
		margin: 60px 0;
		> div {
			@include screen ('tabletport') {
				width: calc(60% - 15px);
				display: inline-block;
				vertical-align: middle;
			}
		}
		> figure {
			width: 100%;
			text-align: center;
			img {
				margin: auto;
			}
			@include screen ('tabletport') {
				width: calc(40% - 15px);
				display: inline-block;
				text-align: right;
				vertical-align: middle;
				margin-left: 15px;
				img {
					margin: 0;
				}
			}
		}
		.B2img,
		.P2img {
			@include screen ('tabletport') {
				text-align: left;
				margin-left: 0;
				margin-right: 15px;
			}
		}
	}
	.secB0,
	.secP0 {
		margin-top: 30px;
	}
	.table-responsive {
		border: 0;
	}
	table {
		font-size: 15px;
		thead {
			background-color: $brand-success;
			color: #fff;
			th {
				border-bottom: 0;
				vertical-align: top;
			}
		}
	}
}