.homeFirst {
	position: relative;
	.firstText {
		position: relative;
		@include screen ('tabletland') {
			margin-top: 200px;
			margin-bottom: 120px;
		}
		@include screen ('large') {
			margin-bottom: 210px;
		}
	}
	.decoText {
		font-size: 49px;
		line-height: 44px;
		top: -50px;
		left: 0;
		z-index: -100;
		@include screen ('tabletport') {
			top: -260px;
			left: -60px;
			font-size: 90px;
			line-height: 80px;
		}
		@include screen ('tabletland') {
			left: -85px;
			top: -115px;
		}
		@include screen ('large') {
			left: -115px;
		}
	}
	.btn {
		width: 49%;
		@include screen ('tabletland') {
			width: auto;
			padding: $padding-base-vertical $padding-base-horizontal;
			margin-right: 15px;
			margin-top: 15px;
		}
	}
}

.firstSlider {
	@include screen ('tabletport') {
		width: 750px;
		margin-right: auto;
		margin-left: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
	@include screen ('tabletland') {
		width: 992px;
		position: absolute;
		right: 0;
		left: 0;
		margin: auto;
		top: 50%;
		transform: translateY(-50%);
		padding-left: 496px;
	}
	@include screen ('large') {
		width: 1200px;
		padding-left: 615px;
	}
	figure {
		position: relative;
		width: 100%;
		min-height: 90vw;
		@include screen ('tabletport') {
			min-height: 500px;
		}
		@include screen ('tabletland') {
			min-height: 500px;
		}
		.square {
			z-index: -1;
			position: absolute;
			top: 10vw;
			left: 19vw;
			width: 47vw;
			height: 47vw;
			box-shadow: 0 14px 21px 0 rgba(0, 0, 0, 0.3);
			@include screen ('tabletport') {
				top: 80px;
				width: 250px;
				height: 250px;
				left: 250px;
			}
			@include screen ('tabletland') {
				left: 70px;
				top: 60px;
			}
		}
		img {
			width: 80vw;
			height: auto;
			position: absolute;
			top: 0;
			left: 35px;
			@include screen ('tabletport') {
				width: 450px;
				left: 270px;
			}
			@include screen ('tabletland') {
				left: 20px;
			}
			@include screen ('large') {
				width: 500px;
			}
		}
		figcaption {
			position: absolute;
			transform: rotate(-90deg);
			text-transform: uppercase;
			font-weight: 700;
			font-size: 3vw;
			text-align: right;
			top: 32vw;
			width: 47vw;
			left: -8vw;
			z-index: -1;
			@include screen ('tabletport') {
				font-size: $font-size-base;
				width: 250px;
				top: 193px;
				left: 107px;
			}
			@include screen ('tabletland') {
				left: -70px;
				top: 173px;
			}
		}
	}
	.slideGindom {
		.square {
			background-color: $brand-primary;
		}
		figcaption {
			color: $brand-primary;
		}
	}
	.slideCube {
		.square {
			background-color: $brand-success;
		}
		figcaption {
			color: $brand-success;
		}
	}
}

.homeSecond {
	color: #fff;
	.row {
		padding-top: 30px;
		padding-bottom: 20px;
		@include screen ('tabletport') {
			padding-top: 45px;
			padding-bottom: 30px;
		}
	}
	/* .secondCol {
		color: #fff;
		padding: 30px 0;
		@include screen ('tabletport') {
			padding: 30px 0;
		}
		@include screen ('tabletland') {
			column-count: 2;
			column-gap: $grid-gutter-width;
			padding: 30px 0;
		}
	} */
	.secondVideo {
		margin: 0 15px;
		@include screen ('tabletport') {
			margin: 0 20px;
		}
		> a {
			display: block;
		}
		figure {
			position: relative;
			overflow: hidden;
			width: 100%;
			height: 200px;
			@include screen ('tabletport') {
				height: 400px;
			}
			.videoBg {
				height: 100%;
				width: auto;
				max-width: unset;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
			.videoPlay {
				z-index: 100;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				width: 50px;
				@include screen ('tabletport') {
					width: 100px;
				}
			}
		}
	}
}

.clientLogos {
	@include screen ('tabletport') {
		margin-top: 120px;
	}
	.logoSlider {
		.slick-dots {
			text-align: center;
		}
	}
	img {
		padding: 3vw;
		@include screen ('tabletport') {
			padding: 10px;
		}
		@include screen ('tabletland') {
			padding: 20px;
		}
	}
}

.newsHome {
	position: relative;
	@include screen ('tabletport') {
		margin-top: 60px;
		padding-top: 60px;
		padding-bottom: 15px;
	}
	@include screen ('tabletland') {
		padding-top: 120px;
	}
	> figure {
		margin: 15px;
		height: 100vw;
		overflow: hidden;
		margin-bottom: 120px;
		@include screen ('mini') {
			height: 60vw;
		}
		@include screen ('tabletport') {
			position: absolute;
			top: 0;
			right: calc(50% - 375px);
			width: 345px;
			height: 450px;
		}
		@include screen ('tabletland') {
			right: calc(50% - 490px);
    		width: 460px;
    		height: 500px;
		}
		@include screen ('large') {
			right: calc(50% - 590px);
    		width: 470px;
		}
		img {
			position: absolute;
			min-height: 100vw;
			width: auto;
			max-width: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include screen ('tabletport') {
				min-height: 450px;
			}
			@include screen ('tabletland') {
				min-height: 500px;
			}
		}
	}
	.decoText {
		top: -60px;
		left: -6vw;
		@include screen ('tabletport') {
			left: -16%;
		}
	}
	a {
		margin-top: 10px;
	}
}

.productHome {
	padding-top: 30px;
	@include screen ('tabletport') {
		padding-top: 0;
		padding-bottom: 60px;
	}
}

.newsHome + .productHome {
	padding-top: 30px;
	@include screen ('tabletport') {
		padding: 120px 0 60px;
	}
	@include screen ('tabletland') {
		padding-top: 200px;
	}
}

.productGindom,
.productCube {
	position: relative;
	display: block;
	text-transform: uppercase;
	font-weight: 700;
	padding: 15px;
	height: 105vw;
	@include screen ('mini') {
		max-height: 640px;
		max-width: 540px;
		margin: auto;
	}
	@include screen ('tabletport') {
		height: 400px;
	}
	@include screen ('tabletland') {
		padding: 30px;
		height: 640px;
	}
	.decoText {
		font-size: 16vw;
		line-height: 14vw;
		position: absolute;
		z-index: -1;
		top: 60px;
		@include screen ('mini') {
			font-size: 90px;
			line-height: 80px;
		}
		@include screen ('large') {
			top: 90px;
		}
	}
	img {
		width: 85%;
	    position: absolute;
	    margin-left: 5%;
	    @include screen ('tabletland') {
	    	width: 100%;
	    }
	}
	h1 {
		margin-left: 25%;
		@include screen ('tabletport') {
			font-size: 27px;
		}
	}
	.square {
		width: 50vw;
		height: 50vw;
		margin: 25% 0 15% 25%;
		@include screen ('mini') {
			width: 320px;
			height: 320px;
		}
		@include screen ('tabletport') {
			width: 200px;
			height: 200px;
		}
		@include screen ('tabletland') {
			width: 300px;
			height: 300px;
			margin: 35% 0 15% 25%;
		}
		@include screen ('large') {
			width: 370px;
			height: 370px;
			margin-bottom: 10%;
		}
	}
}

.productGindom {
	@include screen ('tabletport') {
		margin-top: 120px;
	}
	.square {
		background-color: $brand-primary;
	}
}

.productCube {
	color: $brand-success;
	img {
		@include screen ('tabletland') {
			margin-left: -10%;
		}
	}
	.square {
		background-color: $brand-success;
	}
	&:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	color: darken($brand-success, 15%);
    }
}