.page:first-of-type {
	margin-top: 120px;
}

.decoText {
	position: absolute;
	text-transform: uppercase;
	font-size: 90px;
	line-height: 80px;
	opacity: .11;
	font-weight: 100;
	span {
		font-weight: 700;
	}
}

.cubeText {
	color: $brand-success;
}

.scrolled {
	box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
}

.fixed {
	position: fixed;
	top: 60px;
	z-index: 900;
	left: 0;
    right: 0;
    margin: auto;
	@include screen ('tabletland') {
		top: 80px;
	}
}

.btn {
	text-transform: uppercase;
	font-size: $btn-font-size;
	transition: all 400ms;
	box-shadow: 0 10px 30px 0 rgba(44, 44, 44, 0.3);
	border-width: 0px;
	&:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	box-shadow: 0 10px 30px 0 rgba(44, 44, 44, 0.5);
    	border-width: 0px;
    }
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	box-shadow: 0 10px 30px 0 rgba(44, 44, 44, 0.5);
    	border-width: 0px;
    	outline: 5px auto $brand-primary;
  		outline-offset: -2px;
    }
}

.btn-success {
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	outline: 5px auto $brand-success;
    }
}

.btn-read {
	padding: 0;
	box-shadow: none;
	color: inherit;
	text-transform: inherit !important;
	font-weight: 700;
	font-size: inherit;
	vertical-align: baseline;
	&:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	color: inherit;
    	outline: none;
    	box-shadow: none;
    	border-width: 0px;
    }
}

.btn-darker {
	background-color: darken($brand-primary, 7%);
}

.maintenance {
	min-height: calc(100vh - 250px);
	height: auto;
	figure {
		width: 100%;
		height: 200px;
		overflow: hidden;
		@include screen ('tabletland') {
			height: 400px;
		}
		img {
			max-width: none;
			width: 700px;
			height: 200px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			@include screen ('tabletland') {
				width: 1400px;
				height: 400px;
			}
		}
		figcaption {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: 50%;
			transform: translateY(-50%);
			z-index: 100;
			color: #fff;
			font-weight: 700;
			text-transform: uppercase;
			font-size: $font-size-large;
			@include screen ('tabletport') {
				font-size: $font-size-large * 2;
			}
		}
	}
}

.picShadow {
	box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.3);
	&:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
    	box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.3) !important;
    }
}

.picFilter {
	position: relative;
	&:after {
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
	}
}

.picBorder {
	border-bottom: 7px solid transparent;
}

.picGindom {
	border-color: $brand-primary;
	&:after {
		background-color: rgba($brand-primary, .5);
	}
}

.picCube {
	border-color: $brand-success;
	&:after {
		background-color: rgba($brand-success, .5);
	}
}

.bgSolid {
	position: relative;
	&:before {
		z-index: -1;
		content: '';
		background-color: $white;
		position: absolute;
		width: calc(100% - 30px);
		height: 100%;
		top: 0;
		left: 15px;
		@include screen ('tabletport') {
			width: calc(100% - 40px);
			left: 20px;
		}
	}
	.container {
		padding: 0 40px;
		@include screen ('tabletport') {
			padding: 0 50px;
		}
		@media (min-width: 815px) {
			padding: 0 15px;
		}
		@include screen ('tabletland') {
			padding: 0 50px;
		}
		@media (min-width: 1064px) {
			padding: 0 15px;
		}
		@include screen ('large') {
			padding: 0 50px;
		}
		@media (min-width: 1280px) {
			padding: 0 15px;
		}
		.row {
			margin: 0 -20px;
			@include screen ('tabletport') {
				margin: 0 -25px;
			}
			@media (min-width: 815px) {
				margin: 0 -15px;
			}
			@include screen ('tabletland') {
				margin: 0 -25px;
			}
			@media (min-width: 1064px) {
				margin: 0 -15px;
			}
			@include screen ('large') {
				margin: 0 -25px;
			}
			@media (min-width: 1280px) {
				margin: 0 -15px;
			}
		}
	}
}

.bgGindom {
	&:before {
		background-color: $brand-primary;
	}
}

.bgCube {
	&:before {
		background-color: $brand-success;
	}
}

.modal-content {
	border-radius: 0px;
	border: 0px;
	background-color: $white;
}

.modal-header,
.modal-body {
	padding: 15px;
	border: 0px;
	@include screen ('tabletland') {
		padding: 20px;
	}
	button {
		font-size: 27px;
		line-height: .75;
	}
	.form-group > button {
		font-size: $btn-font-size;
		line-height: 1.25;
	}
}

.modal-header {
	padding-bottom: 0;
}

.modal-xl {
	width: 90vw;
	@include screen ('tabletland') {
		width: 80vw;
	}
}

.slick-slider,
.slider {
	* {
		&:hover,
	    &:focus,
	    &:hover:focus,
	    &.focus,
	    &:active,
	    &.active,
	    &:active:focus,
	    &:active:hover {
	        outline: 0;
	        box-shadow: none;
	        text-decoration: none;
	    }
	}
}

.slick-dots {
	text-align: left; 
    li {
    	width: 10px;
		height: 10px;
		margin: 0 4px;
    	button {
    		padding: 0;
    		width: 10px;
    		height: 10px;
    		position: relative;
    		&:before {
    			font-family: 'Woodford Bourne';
			    font-size: 80px;
			    line-height: 10px;
			    width: 10px;
			    height: 10px;
			    content: '·';
			    text-align: center;
			    opacity: .25;
			    color: $brand-primary;
			    font-weight: 700;
			    transition: opacity 400ms;
			    top: 4px;
			    left: -4px;
    		}
    	}
    }
}

input[type=checkbox].CybotCookiebotDialogBodyLevelButton+label {
	text-transform: none;
}

#CybotCookiebotDialog a, #CybotCookiebotDialog div, #CybotCookiebotDialogBodyContentTitle {
    font-family: inherit;
}

#bodycontent {
	margin-bottom: 30px;
}