$screen-mini: 640px;
$screen-tabletport: 768px;
$screen-medium: 992px;
$screen-tabletland: 1024px;
$screen-large: 1200px;
$screen-full: 1400px;
$screen-fullhd: 1920px;
@mixin screen ($media) {
    @if $media==mini {
        @media only screen and (min-width: $screen-mini) {
            @content;
        }
    }
    @else if $media==tabletport {
        @media only screen and (min-width: $screen-tabletport) {
            @content;
        }
    }
    @else if $media==medium {
        @media only screen and (min-width: $screen-medium) {
            @content;
        }
    }
    @else if $media==tabletland {
        @media only screen and (min-width: $screen-tabletland) {
            @content;
        }
    }
    @else if $media==large {
        @media only screen and (min-width: $screen-large) {
            @content;
        }
    }
    @else if $media==full {
        @media only screen and (min-width: $screen-full) {
            @content;
        }
    }
    @else if $media==fullhd {
        @media only screen and (min-width: $screen-fullhd) {
            @content;
        }
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-ms-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation($stuff) {
    -webkit-animation: $stuff;
    -moz-animation: $stuff;
    -o-animation: $stuff;
    animation: $stuff;
}

// Utilizzo dello script sopra
// @include keyframes(arrowDown){
//   0%     { }
//   50%   { }
//   100%   { }
// }
@mixin circle($width, $color, $sizeBorder) {
    width: $width;
    height: $width;
    background: $color;
    border: $sizeBorder solid $color;
    @include border-radius($width/2);
}
