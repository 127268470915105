

//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$brand-primary: #003087; // was #224b8f
$brand-success: #940025 !default; // was #82122e
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #2c2c2c !default;

$white: #f2f2f2;
$cobalt-50: rgba($brand-primary, 0.5);


//== Scaffolding
//
//## Settings for some of the most global styles.
//** Background color for `<body>`.
$body-bg: #fff !default;
//** Global text color on `<body>`.
$text-color: $brand-primary !default;
//** Global textual link color.
$link-color: $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($brand-primary, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: none !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Woodford Bourne', Arial, Helvetica, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 19px !default;
$font-size-large: ceil(($font-size-base * 1.1)) !default; // ~21px
$font-size-small: floor(($font-size-base * 0.9)) !default; // ~17px
$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~px
//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.25 !default;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~px
//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 700 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical: 10px !default;
$padding-base-horizontal: 23px !default;
$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;
$padding-small-vertical: 10px !default;
$padding-small-horizontal: 14px !default;
$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;
$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;
$border-radius-base: 5px !default;
$border-radius-large: 6px !default;
$border-radius-small: 4px !default;
//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default;
//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default;
//Aggiungi qui le variabili di bootstrap che vuoi sovrascrivere


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:             700 !default;
$btn-font-size:               15px !default;

$btn-default-color:           $brand-primary !default;
$btn-default-bg:              transparent !default;
$btn-default-border:          transparent !default;

$btn-link-disabled-color:     $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:      $border-radius-base !default;
$btn-border-radius-large:     $border-radius-large !default;
$btn-border-radius-small:     $border-radius-small !default;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color:                    $brand-primary !default;
//** `<input>` border color
$input-border:                   $brand-primary !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-small !default;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:             $brand-primary !default;

//** Placeholder text color
$input-color-placeholder:        $brand-primary !default;

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//** `.form-group` margin
$form-group-margin-bottom:       20px !default;

$legend-color:                   $gray-dark !default;
$legend-border-color:            #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;


//== Wells
//
//##

$well-bg:                     transparent !default;
$well-border:                 transparent !default;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  1024px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (962px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;