header {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	width: 100%;
	height: 60px;
	z-index: 1000;
	padding: 15px 0;
	transition: box-shadow 800ms;
	@include screen ('tabletport') {
		padding: 12px 0;
	}
	@include screen ('tabletland') {
		height: 80px;
		padding: 7px 0;
	}
	.container {
		position: relative;
	}
	.headerLogo {
		width: 85px;
		height: 30px;
		display: block;
		display: inline-block;
		vertical-align: top;
		overflow: hidden;
		@include screen ('tabletport') {
			width: 100px;
			height: 37px;
		}
		@include screen ('tabletland') {
			width: 150px;
			height: 66px;
		}
		a {
			display: block;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.hamburger {
		position: absolute;
		right: 15px;
		top: 5px;
		@include screen ('tabletland') {
			display: none;
		}
	}
	.hamburger-open {
		z-index: 1000;
		transition: top 200ms, right 200ms;
	}
	.hamburger-close {
		z-index: 2000;
		top: 10px;
		.hamburger-inner::before, .hamburger-inner::after {
			background-color: #fff;
		}
	}
}
nav {
	text-transform: uppercase;
	font-weight: 400;
	font-size: 22.5px;
	position: fixed;
	width: 100%;
	min-height: 100%;
	max-height: 100%;
	background-color: $brand-primary;
	color: #fff;
	top: 0;
	z-index: 1000;
	padding: 97px 0px 54px;
	overflow-y: auto;
	transition-timing-function: cubic-bezier(0.7, 0, 0.35, 1);
	@include screen ('tabletport') {
		padding-top: 117px;
	}
	@include screen ('tabletland') {
		position: relative;
		background-color: transparent;
		right: unset;
		top: unset;
		width: auto;
		height: auto;
		z-index: 1000;
		display: inline-block;
		float: right;
		padding: 0;
		padding-top: 23px;
		overflow-y: visible;
		color: $brand-primary;
		font-size: 15px;
	}
	.menuTitle {
		top: 50px;
		left: 5%;
		z-index: -1;
		@include screen ('tabletland') {
			display: none;
		}
	}
	ul {
		@include screen ('tabletland') {
			margin-bottom: 0;
		}
		li {
			margin-left: 17%;
			margin-bottom: 40px;
			@include screen ('tabletland') {
				margin: 0 10px;
				display: inline-block;
				vertical-align: top;
			}
			.btn-menu {
				font-weight: 400;
				color: #fff;
				box-shadow: none;
				outline: 0;
				@include screen ('tabletland') {
					color: $brand-primary;
					padding: $padding-small-vertical $padding-small-horizontal;
					transition: all 400ms;
					background-color: transparent;
					border-radius: $border-radius-base;
				}
				&:hover,
			    &:focus,
			    &:hover:focus,
			    &.focus,
			    &:active,
			    &.active,
			    &:active:focus,
			    &:active:hover {
			    	@include screen ('tabletland') {
						box-shadow: none;
						outline: 0;
						background-color: $brand-primary;
						color: #fff;
			    	}
			    }
			}
			.btn-menu.current {
				@include screen ('tabletland') {
					color: #fff;
					background-color: $brand-primary;
				}
			}
			.btn-collapse {
				position: relative;
				cursor: pointer;
				&:after {
					display: inline-block;
					content: '-';
					font-size: 51px;
					line-height: 0;
					font-weight: 100;
					vertical-align: sub;
					transform:scale(1.4,1.1);
					@include screen ('tabletland') {
						display: none;
					}
				}
			}
			.btn-collapse.collapsed {
				&:after {
					content: '+';
					font-size: 40px;
					transform:scale(1,1);
					font-weight: 400;
					@include screen ('tabletland') {
						display: none;
					}
				}
			}
			#products {
				margin-left: -10%;
				@include screen ('tabletland') {
					margin-left: 0;
					position: fixed;
					z-index: 200;
					background-color: $brand-primary;
					width: 100%;
					height: 360px;
					left: 0;
					top: 80px;
					transition: height 400ms 0ms;
					overflow: visible;
					text-align: center;
				}
				ul {
					background-color: transparent;
				    border: 0px;
				    border-radius: 0px;
				    box-shadow: none;
				    margin: 0;
				    padding: 15px 0 0;
				    opacity: 1;
				    transition: opacity 400ms 200ms;
				    @include screen ('tabletland') {
				    	padding-top: 30px;
				    }
				    li {
				    	margin: 0;
				    	@include screen ('tabletport') {
				    		width: calc(50% - 5px);
							display: inline-block;
				    	}
				    	@include screen ('tabletland') {
				    		margin: 0 15px;
				    		min-width: 415px;
				    		width: auto;
				    		text-align: left;
				    	}
				    }
				    .btn-product {
				    	color: #fff;
				    	font-size: 13px;
				    	font-weight: 700;
				    	display: block;
				    	&:hover,
					    &:focus,
					    &:hover:focus,
					    &.focus,
					    &:active,
					    &.active,
					    &:active:focus,
					    &:active:hover {
					    	background-color: rgba(#fff, .1);
					    }
				    	@include screen ('tabletland') {
				    		font-size: $font-size-base;
				    		padding: 15px;
				    	}
				    	img {
				    		width: 120px;
				    		@include screen ('tabletland') {
				    			width: 200px;
				    		}
				    	}
				    	span {
				    		width: 50px;
				    		display: inline-block;
				    		@include screen ('tabletland') {
								width: auto;
				    		}
				    	}
				    }
				}
			}
			#products.closedLi {
				@include screen ('tabletland') {
					height: 0;
					overflow: hidden;
					transition: height 200ms 100ms;
					ul {
						opacity: 0;
						transition: opacity 200ms 0ms;
					}
				}
			}
		}
	}
	.menuLang {
		// padding-top: 14px;
		@include screen ('tabletport') {
			// padding-top: 27px;
		}
		@include screen ('tabletland') {
			// padding-top: 0;
			padding-left: 15px;
		}
		.btn-menu {
			// font-size: 15px;
			@include screen ('tabletland') {
				margin-left: 0;
				padding-left: 0;
				padding-right: 0;
			}
			&:hover,
		    &:focus,
		    &:hover:focus,
		    &.focus,
		    &:active,
		    &.active,
		    &:active:focus,
		    &:active:hover {
		    	background-color: transparent;
		    	color: $brand-primary;
		        // outline: 0;
		        // box-shadow: none;
		        text-decoration: underline;
		    }
		}
	}
}

.navOpen {
	right: 0;
	border: 15px solid #fff;
	transition: right 400ms 0ms, border 200ms 400ms;
}

.navClose {
	right: -100%;
	border: 0px solid #fff;
	transition: border 100ms 0ms, right 400ms 100ms;
	@include screen ('tabletland') {
		right: 0;
	}
}