.img-comp-container {
  position: relative;
  width: 290px;
  height: 200px; /*should be the same height as the images*/
  margin: 0 auto 20px;
  overflow: hidden;
  @include screen ('mini') {
    width: 580px;
    height: 400px;
  }
  @include screen ('tabletport') {
    width: 720px;
    height: 300px;
  }
  @include screen ('tabletland') {
    width: 962px;
    height: 253px;
  }
  @include screen ('large') {
    width: 1140px;
    height: 300px;
  }
}

.img-comp-img {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
  img {
    display: block;
    vertical-align: middle;
    max-width: none;
    width: 290px;
    height: 200px;
    @include screen ('mini') {
      width: 580px;
      height: 400px;
    }
    @include screen ('tabletport') {
      width: 720px;
      height: 300px;
    }
    @include screen ('tabletland') {
      width: 962px;
      height: 253px;
    }
    @include screen ('large') {
      width: 1140px;
      height: 300px;
    }
  }
}

.img-comp-overlay {
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    top: 0;
    right: 0;
    background-image: linear-gradient(to bottom, #fff 20%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 10px;
    background-repeat: repeat-y;
  }
}

.img-comp-slider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  width: 50px;
  height: 50px;
  background-image: url('../../../assets/images/elements/slider.svg');
  background-size: contain;
  background-repeat: no-repeat;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 14px;
    background-image: url('../../../assets/images/elements/arrow-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
  &:before {
    left: -30px;
    transform: rotate(90deg);
  }
  &:after {
    right: -30px;
    transform: rotate(-90deg);
  }
}