#map {
	height: 90vw;
	background-color: $white;
	margin-bottom: 60px;
	@include screen ('tabletport') {
		height: 400px;
		margin-bottom: 120px;
	}
}

.contacts {
	form {
		margin-top: 30px;
		@include screen ('tabletport') {
			margin-top: 0;
		}
		.name,
		.surname {
			@include screen ('mini') {
				width: 47.5%;
				display: inline-block;
				margin-right: -5px;
			}
			@include screen ('tabletport') {
				width: 100%;
				display: block;
				margin-right: 0;
			}
			@include screen ('tabletland') {
				width: 46.5%;
				display: inline-block;
				margin-right: -5px;
			}
		}
		.surname {
			@include screen ('mini') {
				margin-left: 30px;
				float: right;
				margin-right: 0px;
			}
			@include screen ('tabletport') {
				margin-left: 0;
				float: none;
			}
			@include screen ('tabletland') {
				margin-left: 30px;
				float: right;
			}
		}
	}
}

.checkbox-inline {
	text-transform: none !important;
	width: 100%;
}

.form-control {
	font-size: 15px;
	border-width: 2px;
	padding: $padding-small-vertical $padding-small-horizontal;
	height: 38px;
	line-height: $headings-line-height;
}

span.form-group,
span.input-group-btn {
	display: block;
	text-align: right;
	button {
		min-width: 150px;
	}
}


input[type="checkbox"] {
	appearance: none;
	-webkit-appearance: none;
	border: 0px;
	border-radius: 0px;
	width: 16px;
	height: 16px;
	vertical-align: middle;
	cursor: pointer;
	margin: 0;
	background: url("../../images/elements/checks.png") no-repeat 0 0;
	&:focus {
		outline: none;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(34, 75, 143, 0.6);
		background: url("../../images/elements/checks.png") no-repeat 0 0;
	}
	&:checked {
		background: url("../../images/elements/checks.png") no-repeat 0 -32px;
	}
}

.checkbox-inline.dark {
	margin-top: 15px;
	font-size: 15px;
	font-weight: 100;
	a {
		color: #fff;
		text-decoration: underline;
		&:hover,
	    &:focus,
	    &:hover:focus,
	    &.focus,
	    &:active,
	    &.active,
	    &:active:focus,
	    &:active:hover {
	    	color: #fff;
	    }
	}
	input[type="checkbox"] {
		background: url("../../images/elements/checks-white.png") no-repeat 0 0;
		&:focus {
			box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
			background: url("../../images/elements/checks-white.png") no-repeat 0 0;
		}
		&:checked {
			background: url("../../images/elements/checks-white.png") no-repeat 0 -32px;
		}
	}
}

label {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
}

.alert {
	> button {
		@extend .btn;
		@extend .btn-xs;
		@extend .btn-default;
		float: right;
		font-size: 24px !important;
    	line-height: 1;
	}
}

.alert-success,
.alert-success > button {
	color: green;
}

.alert-error,
.alert-error > button {
	color: red;
}