.company {
	&:first-of-type {
		@include screen ('tabletport') {
			margin-top: 120px;
		}
		@include screen ('tabletland') {
			margin-top: 160px;
		}
		@include screen ('large') {
			margin-top: 200px;
		}
	}
	.companyImg {
		margin-bottom: 120px;
		@include screen ('tabletport') {
			float: right;
			margin-bottom: 0;
		}
		figure {
			@include screen ('tabletport') {
				margin-top: 15px;
			}
			img {
				width: 100%;
			}
		}
	}
	.decoText {
		left: -8%;
		top: -60px;
		@include screen ('tabletport') {
			top: -23px;
		}
		@include screen ('tabletland') {
			left: -16%;
		}
	}
	.certifications {
		margin-top: 60px;
		margin-bottom: 30px;
	}
	.certImg {
		width: 40px;
	}
	.certTxt {
		font-size: 15px;
		display: inline-block;
		width: calc(100% - 60px);
		margin-left: 15px;
		margin-bottom: 0;
		vertical-align: middle;
	}
	.partnerTxt {
		margin-top: 45px;
		font-size: 15px;
		@include screen ('tabletport') {
			margin-top: 0;
		}
	}
	.partnerImg {
		width: 85px;
	}
}