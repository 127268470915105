body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    font-weight: 100;
    font-size: $font-size-small;
    @include screen ('tabletport') {
        font-size: $font-size-base;
    }
    @include screen ('large') {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
}

section {
    margin-bottom: 35px;
    &:first-of-type {
        margin-top: 60px;
    }
}

a {
    &:hover,
    &:focus,
    &:hover:focus,
    &.focus,
    &:active,
    &.active,
    &:active:focus,
    &:active:hover {
        outline: 0;
        box-shadow: none;
        text-decoration: none;
    }
}

img {
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
    font-size: $font-size-base;
    line-height: $headings-line-height;
    margin: 0;
    text-transform: uppercase;
    margin: 0 0 ($line-height-computed / 2);
}

h1,
.h1 {
    font-size: 27px;
    @include screen ('tabletport') {
        font-size: 36px;
    }
}

h2,
.h2 {
    font-size: 25px;
    @include screen ('tabletport') {
        font-size: 31px;
    }
}

h3,
.h3 {
    font-size: $font-size-large;
    @include screen ('tabletport') {
        font-size: 27px;
    }
}

p,
.p {
    margin: 0 0 ($line-height-computed / 2);
    font-weight: 100;
    line-height: $line-height-base;
    text-transform: none;
    font-size: $font-size-small;
    @include screen ('tabletport') {
        font-size: $font-size-base;
    }
}

.lead {
    @extend .p;
    font-weight: 700;
    text-transform: uppercase;
}