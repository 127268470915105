footer {
	color: #fff;
	padding: 15px 0 30px;
	.footerLogo {
		width: 120px;
		margin-bottom: 30px;
		@include screen ('tabletland') {
			width: 150px;
		}
	}
	form {
		@include screen ('tabletport') {
			margin-top: 15px;
		}
		@include screen ('tabletland') {
			margin-top: 20px;
		}
		@include screen ('large') {
			text-align: right;
		}
	}
	label {
		color: #fff;
		text-transform: uppercase;
		font-weight: 100;
		line-height: $line-height-base;
		font-size: $font-size-small;
		@include screen ('tabletport') {
		    font-size: $font-size-base;
		}
		@include screen ('large') {
			display: inline-block;
			width: 30%;
			vertical-align: top;
			margin-top: 0;
			text-align: left;
		    float: left;
		}
	}
	.form-control {
		color: #fff;
		background-color: transparent;
		border-color: #fff;
		border-width: 1px;
		&:focus {
			border-color: #fff;
		    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 255, 255, 0.6);
		}
		&::placeholder {
			color: #fff;
		}
		@include screen ('large') {
			display: inline-block;
			width: 65%;
			vertical-align: top;
			text-align: left;
		}
	}
	.clear {
		margin-top: 15px;
		text-align: right;
	}
	.footerService {
		text-align: right;
		margin-top: 30px;
		a {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 13px;
			color: #fff;
			&:hover,
		    &:focus,
		    &:hover:focus,
		    &.focus,
		    &:active,
		    &.active,
		    &:active:focus,
		    &:active:hover {
		    	color: #fff;
		    }
		}
	}
}

address {
	margin: 20px 0 25px;
	font-size: $font-size-small;
}